/* eslint-disable jsx-a11y/anchor-is-valid no-useless-escape*/

import * as React from "react"
import { Modal } from "react-bootstrap"
import ReactGA from "react-ga"

export default class SubmissionThankYou extends React.PureComponent {
  closeModal = () => {
    ReactGA.ga("send", "event", "Design4", "Submission Thankyou", "Close")
    this.props.onclickClose()
  }
  render() {
    if (!this.props.show) return null

    return (
      <Modal
        show
        onHide={() => this.props.onclickClose()}
        dialogClassName="align-center"
        size="lg"
      >
        <Modal.Body>
          <div className="clearer" />
          <h1>Awesome!</h1>
          <p>
            Thank you for your submission! Our team is checking out the video,
            and if the video is accepted, we will email you the next steps. In
            the meantime, complete this form:{" "}
            <a target="_blank" href="https://jukinmedia.typeform.com/to/RtwuLd">
              https://jukinmedia.typeform.com/to/RtwuLd
            </a>{" "}
            with a few questions about the video.
          </p>
          <div className="clearer" />
          <button className="fa-button black-button" onClick={this.closeModal}>
            Close
          </button>
        </Modal.Body>
      </Modal>
    )
  }
}
